import { generateSimpleMutations } from '@/utils/state'

export const state = () => {
  return {
    user: null,
    rewards: null,
  }
}

export const mutations = {
  ...generateSimpleMutations(Object.keys(state())),
  SET_BONUSES(state, payload) {
    state.user.bonuses = payload
  },
  SET_SHOTS_AVAILABLE(state, payload) {
    state.user.shots_available = payload
  },
}

export const getters = {
  user(state) {
    return state.user
  },
  bonuses(state) {
    return state.user?.bonuses ?? 0
  },
  shotsAvailable(state) {
    return state.user?.shots_available ?? 0
  },
}
