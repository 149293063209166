export default function ({ $auth, store }) {
  if ($auth.loggedIn) {
    return
  }
  const user = store?.state?.user
  if (user?.user) {
    store.commit('user/SET_USER', null)
    store.commit('user/SET_REWARDS', null)
  }
}
