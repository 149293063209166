export default ({ $axios, redirect, $cookies, $modal }, inject) => {
  inject('sendRequest', (method, url, params = {}, config = {}) => {
    if (!method) {
      return $axios
    }
    if (method === 'get' && !params.params) {
      // для запросов со скачиванием файлов формируем params сразу
      params = { params }
    }

    return $axios[`$${method}`](url, params, config)
  })
}
