export default ($sendRequest) => ({
  getList() {
    return $sendRequest('get', '/api/game/popups/list')
  },
  read(popupIds) {
    return $sendRequest('post', '/api/game/popups/read', {
      popup_ids: popupIds,
    })
  },
})
