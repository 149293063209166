
export default {
  name: 'BaseLock',
  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
  },
}
