
import { mapState } from 'vuex'
import Hint from '@/components/Hint'

export default {
  name: 'MenuLayout',
  components: { Hint },
  data() {
    return {
      page: 'home',
      menuHighlighted: false,
    }
  },
  computed: {
    ...mapState('layout', ['pageName']),
  },
  mounted() {
    this.$store.dispatch('layout/calculateWindowHeight')
    this.page = this.$route.name
    this.$modal.eventBus.$on('highlight-main-menu', () => {
      this.menuHighlighted = true
    })
    this.$modal.eventBus.$on('stop-highlight-main-menu', () => {
      this.menuHighlighted = false
    })
  },
  methods: {
    changePage(page) {
      this.page = page
    },
  },
}
