export default ($sendRequest) => {
  return {
    getList() {
      return $sendRequest('get', '/api/game/booster/list')
    },
    use(id) {
      return $sendRequest('post', '/api/game/booster/use', {
        booster_id: id,
      })
    },
    getProductList() {
      return $sendRequest('get', '/api/game/booster/buy/list')
    },
    buy(params) {
      return $sendRequest('post', '/api/game/booster/buy', params)
    },
  }
}
