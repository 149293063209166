export default ($sendRequest) => {
  return {
    getLocation() {
      return $sendRequest('get', '/api/game/location/info')
    },
    shot(x, y) {
      return $sendRequest('post', '/api/game/shot', {
        x,
        y,
      })
    },
    getPackages() {
      return $sendRequest('get', '/api/game/shots/buy/list')
    },
    getReloadPrice() {
      return $sendRequest('get', '/api/game/location/reload-price')
    },
    resetMap() {
      return $sendRequest('post', '/api/game/location/reload')
    },
    freeResetMap() {
      return $sendRequest('post', '/api/game/location/reload/free')
    },
    buySteps(id) {
      return $sendRequest('post', '/api/game/shots/buy', {
        pack_id: id,
      })
    },
  }
}
