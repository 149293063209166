export default ($sendRequest) => {
  return {
    getProductList() {
      return $sendRequest('get', '/api/game/shots/buy/list')
    },
    buy(params) {
      return $sendRequest('post', '/api/game/shots/buy', params)
    },
  }
}
