import { generateSimpleMutations } from '@/utils/state'

export const state = () => {
  return {
    allPrizes: null,
    userPrizes: null,
    eyePrizes: [],
  }
}

export const getters = {
  gameBonuses(state) {
    return state.userPrizes?.bonuses ?? 0
  },
  discounts(state) {
    return state.userPrizes?.discounts ?? []
  },
  promocodes(state) {
    return state.userPrizes?.promocodes ?? []
  },
}

export const mutations = {
  ...generateSimpleMutations(Object.keys(state())),
}
