export default ($sendRequest) => {
  return {
    sendSms(phone, birthdate) {
      return $sendRequest('post', '/api/auth1', {
        phone,
        birthdate,
      })
    },
    registerSms(phone, birthdate, acceptRules, acceptPersonal) {
      return $sendRequest('post', '/api/register1', {
        phone,
        birthdate,
        accept_rules: acceptRules,
        accept_personal: acceptPersonal,
      })
    },
  }
}
