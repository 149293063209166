
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
export default {
  name: 'ModalWrapper',
  inheritAttrs: false,
  props: {
    iconClose: {
      type: Boolean,
      default: true,
    },
    selfClose: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    padding: {
      type: String,
      default: '', // '' | 'mini'
    },
    innerModifier: {
      type: String,
      default: '',
    },
    resolve: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      animEnded: false,
    }
  },
  mounted() {
    this.show = true
    this.$parent.$on('close', this.hide)
    // disableBodyScroll(this.$el);
  },
  beforeDestroy() {
    this.$parent.$off('close', this.hide)
    // enableBodyScroll(this.$el);
  },
  methods: {
    hide() {
      this.show = false
    },
    closeWithCheck(e, value) {
      if (this.selfClose) {
        this.close(e, value)
      }
    },
    close(e, value) {
      this.resolve(value)
      this.hide()
      this.$emit('close', e)
    },
  },
}
