
import Default from './Default.vue'
import Error from './Error.vue'

export default {
  name: 'ModalInstance',
  components: {
    Default,
    Error,
    // DynamicComponent: () => import('@/components/Modal/DynamicComponent'),
    Choose: () => import('@/components/Modal/Choose'),
    ResetMap: () => import('@/components/Modal/ResetMap'),
    Prize: () => import('@/components/Modal/Prize'),
    Buy: () => import('@/components/Modal/Buy'),
    PromoCode: () => import('@/components/Modal/PromoCode'),
    ChooseBooster: () => import('@/components/Modal/ChooseBooster'),
    Help: () => import('@/components/Modal/Help'),
    Cashback: () => import('@/components/Modal/Cashback'),
    CashbackAbout: () => import('@/components/Modal/CashbackAbout'),
    ThanksBonus: () => import('@/components/Modal/ThanksBonus'),
    AddSteps: () => import('@/components/Modal/AddSteps'),
    CustomError: () => import('@/components/Modal/CustomError'),
    Info: () => import('@/components/Modal/Info'),
    InfoImg: () => import('@/components/Modal/InfoWithImg.vue'),
    PrizeCards: () => import('@/components/Modal/PrizeCards'),
    StepsOver: () => import('@/components/Modal/StepsOver'),
    HelpClassic: () => import('@/components/Modal/HelpClassic'),
  },
  data() {
    return {
      modals: [],
      data: {},
      name: '',
    }
  },
  beforeMount() {
    this.$modal.subscribe(this.onToggle)
  },
  beforeDestroy() {
    this.$modal.unsubscribe(this.onToggle)
  },
  methods: {
    onToggle(name, state, params, resolve) {
      if (!state) {
        this.close(name)
        return
      }
      if (!Array.isArray(name)) {
        name = [name]
      }
      if (!Array.isArray(params)) {
        params = [params]
      }
      for (let i = 0; i < name.length; i++) {
        this.modals.push({
          name: name[i],
          data: {
            ...params[i],
            resolve,
          },
        })
      }

      // this.modals.push({
      //   name,
      //   data: {
      //     ...params,
      //     resolve,
      //   },
      // })
    },
    // open(name, options) {
    //   if (!this.$parent) {
    //     this.$mount();
    //     document.querySelector('#__layout').appendChild(this.$el);
    //   }
    //
    //   return new Promise(resolve => {
    //
    //     this.data = {
    //       ...this.data,
    //       ...options,
    //       resolve
    //     };
    //
    //     this.name = name;
    //   });
    // },
    async close(name) {
      await this.$nextTick() // дожидаемся пересчета переменных враппера (анимация закрытия)
      if (!name) {
        name = this.modals.at(-1).name
      }
      const curModalIndex = this.modals.findIndex(
        (el) => el.name === name || el.data.resolve === name
      )
      if (curModalIndex === -1) {
        throw new Error("Can't find modal " + name)
      }
      const curModal = this.modals[curModalIndex]
      curModal.data.resolve(undefined) // на случай если этого еще не сделано
      this.modals.splice(curModalIndex, 1)
    },
  },
}
