
export default {
  name: 'NavPanel',
  props: {
    current: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menu: [
        {
          name: 'home',
          img: 'island.png',
          title: 'Главная',
        },
        {
          name: 'profile',
          img: 'award.png',
          title: 'Награды',
        },
        {
          name: 'shop',
          img: 'steps.png',
          title: 'Ходы',
        },
        {
          name: 'faq',
          img: 'faq.png',
          title: 'FAQ',
        },
      ],
    }
  },
  methods: {
    goTo(item) {
      if (item.locked) {
        this.$emit('show-hint', { html: 'Пока недоступно' })
        return
      }
      if (this.current === item.name) {
        return
      }
      this.$router.push('/' + item.name)
      this.$emit('change-page', item.name)
    },
  },
}
