import { render, staticRenderFns } from "./default.vue?vue&type=template&id=27dc8a6e&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=27dc8a6e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27dc8a6e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/var/www/new_sber_seabattle/dev/frontend/components/Modal/index.vue').default})
