export default ($sendRequest) => {
  return {
    getLocation() {
      return $sendRequest('get', '/api/game/good-eye/location/info')
    },
    generateLocation(id) {
      return $sendRequest('post', '/api/game/location/generate', {
        option_id: id,
      })
    },
    getOptions() {
      return $sendRequest('get', '/api/game/location/options')
    },
    cancel() {
      return $sendRequest('post', '/api/game/location/cancel')
    },
    shot(x, y) {
      return $sendRequest('post', '/api/game/good-eye/shot', {
        x,
        y,
      })
    },
  }
}
