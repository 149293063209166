
export default {
  name: 'TheHint',
  data() {
    return {
      show: false,
      title: null,
      text: null,
      time: null,
      timeoutId: null,
    }
  },
  computed: {
    showTime() {
      const time =
        this.time && this.time - this.$options.LEAVE_DURATION > 0
          ? this.time
          : this.$options.DEFAULT_TIME
      return time - this.$options.LEAVE_DURATION
    },
  },
  DEFAULT_TIME: 2500,
  LEAVE_DURATION: 500,
  methods: {
    showHint({ title, html, time }) {
      clearTimeout(this.timeoutId)
      this.title = title
      this.html = html
      this.time = time
      if (this.show) {
        this.show = false
      }
      this.show = true
      this.timeoutId = setTimeout(this.hide, this.showTime)
    },
    hide() {
      this.show = false
      this.time = null
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    },
  },
}
