
export default {
  name: 'ThePreloader',
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      if (!this.$parent) {
        this.$mount()
        document.body.appendChild(this.$el)
      }
      this.loading = true
    },
    finish() {
      this.loading = false
    },
  },
}
