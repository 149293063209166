// import ws from './modules/ws'
import auth from './modules/auth'
import game from './modules/game'
import gameEye from './modules/gameEye'
import boosters from './modules/boosters'
import gameClassic from './modules/gameClassic'
import shots from './modules/shots'
import popups from './modules/popups'

export default ({ $sendRequest, store }, inject) => {
  inject('api', {
    // ws: ws($sendRequest, store),
    auth: auth($sendRequest),
    game: game($sendRequest),
    gameEye: gameEye($sendRequest),
    gameClassic: gameClassic($sendRequest),
    boosters: boosters($sendRequest),
    shots: shots($sendRequest),
    popups: popups($sendRequest),
  })
}
