import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=44f59572&scoped=true&"
import script from "./menu.vue?vue&type=script&lang=js&"
export * from "./menu.vue?vue&type=script&lang=js&"
import style0 from "./menu.vue?vue&type=style&index=0&id=44f59572&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f59572",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/var/www/new_sber_seabattle/dev/frontend/components/Modal/index.vue').default,NavPanel: require('/var/www/new_sber_seabattle/dev/frontend/components/NavPanel.vue').default,Hint: require('/var/www/new_sber_seabattle/dev/frontend/components/Hint.vue').default})
