export default ($sendRequest) => {
  return {
    getUserInfo(token) {
      if (!token) {
        return $sendRequest('get', '/api/game/user/info')
      } else {
        return $sendRequest('get', '/api/game/user/info', {
          params: {},
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      }
    },

    getRewards() {
      return $sendRequest('get', '/api/game/rewards')
    },

    getUserRewards() {
      return $sendRequest('get', '/api/game/user/rewards')
    },

    completeTraining(type) {
      return $sendRequest('post', '/api/game/training/complete', {
        flag_name: type,
      })
    },
  }
}
