
export default {
  name: 'ModalDefault',
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    cancelButton: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: 'Отмена',
    },
    okButton: {
      type: Boolean,
      default: true,
    },
    okText: {
      type: String,
      default: 'Ok',
    },
    resolve: {
      type: Function,
      default: () => {},
    },
  },
}
