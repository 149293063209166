
import { mapState } from 'vuex'

export default {
  name: 'DefaultLayout',
  data() {
    return {}
  },
  computed: {
    ...mapState('layout', ['pageName']),
  },
  mounted() {
    this.$store.dispatch('layout/calculateWindowHeight')
  },
}
