
import { mapState } from 'vuex'

export default {
  name: 'PdfLayout',
  computed: {
    ...mapState('layout', ['pageName', 'pageTitle']),
  },
  mounted() {
    this.$store.dispatch('layout/calculateWindowHeight')
    this.page = this.$route.name
  },
}
