import { render, staticRenderFns } from "./NavPanel.vue?vue&type=template&id=738094f7&scoped=true&"
import script from "./NavPanel.vue?vue&type=script&lang=js&"
export * from "./NavPanel.vue?vue&type=script&lang=js&"
import style0 from "./NavPanel.vue?vue&type=style&index=0&id=738094f7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "738094f7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLock: require('/var/www/new_sber_seabattle/dev/frontend/components/BaseLock.vue').default})
